// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/vercel/path0/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-note-js": () => import("/vercel/path0/src/templates/Note.js" /* webpackChunkName: "component---src-templates-note-js" */),
  "component---src-templates-blog-post-js": () => import("/vercel/path0/src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-index-js": () => import("/vercel/path0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notes-js": () => import("/vercel/path0/src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/vercel/path0/.cache/data.json")

